module Main exposing (main)

import AppIndependent.Auth0 exposing (AuthToken(..), authTokenDecoder)
import AppIndependent.ConfigValue as ConfigValue exposing (ConfigValue(..))
import AppIndependent.Domain exposing (ProcessId)
import AppIndependent.Extras.Json.Decode as CCJD
import AppIndependent.Extras.ZipList as CCZipList
import AppIndependent.Nominatim exposing (NominatimAccessToken(..), nominatimAccessTokenDecoder)
import Browser exposing (Document)
import Browser.Navigation as Nav
import CarbonData.Components.EmissionLabel as EmissionLabel
import CarbonData.Domain.Organization exposing (makeOrgIdFromFlag)
import CarbonData.Domain.ProductStatus exposing (ProductStatus(..))
import CarbonData.Page
import CarbonData.Page.About as About
import CarbonData.Page.Blank as Blank
import CarbonData.Page.LogIn as LogIn
import CarbonData.Page.ProductionProcess as ProductionProcess
import CarbonData.Page.Start as Start exposing (Msg(..), StartPageSubSection(..))
import CarbonData.Page.Start.SubSection.Products as Products exposing (ProductPageMode(..))
import CarbonData.Ports as Ports
import CarbonData.Route as Route exposing (Route(..))
import CarbonData.Route.ClimateHub as ClimateHubRoute
import CarbonData.Session exposing (Session, updateSession, userProfileDecoder)
import CarbonData.UserMode as UserMode exposing (UserMode(..))
import ClimateAPI
import Html
import Json.Decode as JD
import Json.Decode.Pipeline as JDP
import Json.Encode as JE
import RemoteData exposing (RemoteData(..))
import Url exposing (Url)
import ZipList



{-
   THIS PAGE ONLY NEEDS TO BE TOUCHED WHEN ADDING A NEW PAGE OR ADDING A PROGRAM FLAG
-}
-- MODEL


type alias Model =
    { activeRoute : Route
    , pageModel : PageModel
    , startPageModel : Start.Model
    }


type PageModel
    = Redirect Session
    | ProductionProcess ProcessId ProductionProcess.Model
    | About About.Model
    | Start
    | LogIn LogIn.Model


init : JE.Value -> Url -> Nav.Key -> ( Model, Cmd Msg )
init flags url navKey =
    let
        decoder =
            JD.succeed (\mRole permissions -> Session navKey [] (UserMode.init mRole permissions))
                |> (JDP.required "roles" <| JD.map UserMode.highestRole <| CCJD.lenientList UserMode.roleDecoder)
                |> (JDP.required "permissions" <| CCJD.lenientList UserMode.permissionDecoder)
                |> JDP.required "authToken" authTokenDecoder
                |> JDP.required "userProfile" userProfileDecoder
                |> JDP.required "apiRoot" JD.string
                |> JDP.required "nominatimToken" (ConfigValue.decoder "nominatim access token" nominatimAccessTokenDecoder)
                |> JDP.custom
                    (CCZipList.decoderWithSelected
                        (JD.field "groups" <| JD.list <| JD.map makeOrgIdFromFlag JD.string)
                        (JD.field "selectedOrganization" <| JD.nullable <| JD.map makeOrgIdFromFlag JD.string)
                    )
                |> JDP.required "climateApiRootUrl" (ConfigValue.decoder "climate api root url" ClimateAPI.rootUrlDecoder)
                |> JDP.required "climateHubRootUrl" (ConfigValue.decoder "climate hub root url" ClimateHubRoute.rootUrlDecoder)
                |> JDP.required "climateLabelRootUrl" (ConfigValue.decoder "climate label root url" EmissionLabel.rootUrlDecoder)
    in
    case JD.decodeValue decoder flags of
        Ok session ->
            let
                ( initModel, _ ) =
                    Start.init session

                ( model, changeRouteCmd ) =
                    changeRouteTo (Route.fromUrl url)
                        { activeRoute = Route.Root
                        , startPageModel = initModel
                        , pageModel = Redirect session
                        }
            in
            ( model
            , Cmd.batch
                [ changeRouteCmd
                , Ports.saveSelectedOrganization <| ZipList.current (toSession model).associatedOrgIds
                ]
            )

        Err _ ->
            let
                session : Session
                session =
                    { navKey = navKey
                    , authToken = AuthToken ""
                    , userProfile =
                        { userId = ""
                        , name = Nothing
                        , email = ""
                        , avatarUrl = ""
                        }
                    , apiRoot = ""
                    , nominatimToken = ConfigValueMissing "nominatim access token not set"
                    , associatedOrgIds = ZipList.singleton (makeOrgIdFromFlag "")
                    , userMode = BasicUser []
                    , orgInfos = []
                    , climateApiRootUrl = ConfigValueMissing "climate api root url"
                    , climateHubRootUrl = ConfigValueMissing "climate hub root url"
                    , climateLabelRootUrl = ConfigValueMissing "climate label root url"
                    }

                ( initModel, initCmd ) =
                    Start.init session
            in
            ( { activeRoute = Route.Root
              , startPageModel = initModel
              , pageModel = LogIn <| LogIn.defaultModel session
              }
            , Cmd.map GotStartMsg initCmd
            )



-- VIEW


view : Model -> Document Msg
view model =
    let
        viewPage toMsg config =
            let
                { title, body } =
                    CarbonData.Page.view config
            in
            { title = title
            , body = List.map (Html.map toMsg) body
            }
    in
    case model.pageModel of
        Redirect _ ->
            viewPage (\_ -> Ignored) Blank.view

        ProductionProcess _ home ->
            viewPage GotProductionProcessMsg (ProductionProcess.view home)

        About about ->
            viewPage GotAboutMsg (About.view about)

        Start ->
            viewPage GotStartMsg (Start.view model.startPageModel)

        LogIn logIn ->
            viewPage GotLogInMsg (LogIn.view logIn)



-- UPDATE


type Msg
    = Ignored
    | ChangedUrl Url
    | ClickedLink Browser.UrlRequest
    | GotProductionProcessMsg ProductionProcess.Msg
    | GotAboutMsg About.Msg
    | GotStartMsg Start.Msg
    | GotLogInMsg LogIn.Msg


toSession : Model -> Session
toSession model =
    case model.pageModel of
        Redirect session ->
            session

        ProductionProcess _ { session } ->
            session

        About { session } ->
            session

        Start ->
            model.startPageModel.session

        LogIn { session } ->
            session


changeRouteTo : Maybe Route -> Model -> ( Model, Cmd Msg )
changeRouteTo maybeRoute model =
    let
        session =
            toSession model

        onStartPage =
            let
                defaultStartPageModel =
                    model.startPageModel

                defaultProductsModel =
                    defaultStartPageModel.productsModel

                ( startPageModel_, startPageCmd ) =
                    Start.init session

                startPageModel =
                    { startPageModel_
                        | productsModel = { defaultProductsModel | pageMode = Overview }
                    }
                        |> (\newModel -> updateSession newModel (\_ -> session))
            in
            ( { model
                | startPageModel = startPageModel
                , pageModel = Start
                , activeRoute = Route.Start
              }
            , Cmd.batch <| List.map (Cmd.map GotStartMsg) [ Start.refreshData startPageModel, startPageCmd ]
            )
    in
    case maybeRoute of
        Nothing ->
            LogIn.init (LogIn.defaultModel session)
                |> updatePageModel LogIn GotLogInMsg { model | activeRoute = Route.Root }

        Just Route.Root ->
            onStartPage

        Just (Route.ProductionProcess pId) ->
            ProductionProcess.init pId session (ProductionProcess.defaultModel session pId)
                |> updatePageModel (ProductionProcess pId) GotProductionProcessMsg { model | activeRoute = Route.ProductionProcess pId }

        Just Route.About ->
            About.init session
                |> updatePageModel About GotAboutMsg { model | activeRoute = Route.About }

        Just Route.Start ->
            onStartPage

        Just Route.ProductOverview ->
            let
                startPageModel =
                    model.startPageModel

                ( productsModel, productsCmd ) =
                    Products.init session ProductsMsg

                newStartPageModel =
                    updateSession
                        { startPageModel
                            | activeSubSection = ProductsSection
                            , productsModel = { productsModel | pageMode = Products.Overview }
                        }
                        (\_ -> session)
            in
            ( { model
                | startPageModel = newStartPageModel
                , pageModel = Start
                , activeRoute = Route.ProductOverview
              }
            , Cmd.batch [ Cmd.map GotStartMsg <| Start.refreshData newStartPageModel, Cmd.map GotStartMsg productsCmd ]
            )

        Just (Route.ProductPage pId) ->
            let
                startPageModel =
                    model.startPageModel

                defaultProductsModel =
                    startPageModel.productsModel
            in
            ( { model
                | startPageModel =
                    { startPageModel
                        | activeSubSection = ProductsSection
                        , productsModel = defaultProductsModel
                    }
                        |> (\newModel -> updateSession newModel (\_ -> session))
                , pageModel = Start
                , activeRoute = Route.ProductPage pId
              }
            , Cmd.map (GotStartMsg << ProductsMsg) <| Products.loadProduct session pId
            )


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case ( msg, model.pageModel ) of
        ( Ignored, _ ) ->
            ( model, Cmd.none )

        ( ClickedLink urlRequest, _ ) ->
            case urlRequest of
                Browser.Internal url ->
                    case url.fragment of
                        Nothing ->
                            -- If we got a link that didn't include a fragment,
                            -- it's from one of those (href "") attributes that
                            -- we have to include to make the RealWorld CSS work.
                            --
                            -- In an application doing path routing instead of
                            -- fragment-based routing, this entire
                            -- `case url.fragment of` expression this comment
                            -- is inside would be unnecessary.
                            ( model, Cmd.none )

                        Just _ ->
                            let
                                session =
                                    toSession model
                            in
                            ( model
                            , Nav.pushUrl session.navKey (Url.toString url)
                            )

                Browser.External href ->
                    ( model
                    , Nav.load href
                    )

        ( ChangedUrl url, _ ) ->
            changeRouteTo (Route.fromUrl url) model

        ( GotProductionProcessMsg pageMsg, ProductionProcess mProcessId home ) ->
            ProductionProcess.update pageMsg home
                |> updatePageModel (ProductionProcess mProcessId) GotProductionProcessMsg model

        ( GotAboutMsg pageMsg, About about ) ->
            About.update pageMsg about
                |> updatePageModel About GotAboutMsg model

        ( GotStartMsg pageMsg, Start ) ->
            Start.update pageMsg model.startPageModel
                |> updateModel (\m spm -> { m | startPageModel = spm, pageModel = Start }) GotStartMsg model

        ( GotLogInMsg pageMsg, LogIn startModel ) ->
            LogIn.update pageMsg startModel
                |> updatePageModel LogIn GotLogInMsg model

        _ ->
            -- Disregard messages that arrived for the wrong page.
            ( model, Cmd.none )


updatePageModel :
    (pageModel -> PageModel)
    -> (pageMsg -> Msg)
    -> Model
    -> ( pageModel, Cmd pageMsg )
    -> ( Model, Cmd Msg )
updatePageModel toPageModel =
    updateModel
        (\model pageModel ->
            { model | pageModel = toPageModel pageModel }
        )


updateModel :
    (Model -> pageModel -> Model)
    -> (pageMsg -> Msg)
    -> Model
    -> ( pageModel, Cmd pageMsg )
    -> ( Model, Cmd Msg )
updateModel modelUpd liftMsg model ( pageModel, subCmd ) =
    ( modelUpd model pageModel
    , Cmd.map liftMsg subCmd
    )



-- SUBSCRIPTIONS


subscriptions : Model -> Sub Msg
subscriptions model =
    case model.pageModel of
        Redirect _ ->
            Sub.none

        ProductionProcess _ productionModel ->
            Sub.map GotProductionProcessMsg <| ProductionProcess.subscriptions productionModel

        About about ->
            Sub.map GotAboutMsg (About.subscriptions about)

        Start ->
            Sub.map GotStartMsg (Start.subscriptions model.startPageModel)

        LogIn logIn ->
            Sub.map GotLogInMsg (LogIn.subscriptions logIn)



-- MAIN


main : Program JE.Value Model Msg
main =
    Browser.application
        { init = init
        , onUrlChange = ChangedUrl
        , onUrlRequest = ClickedLink
        , subscriptions = subscriptions
        , update = update
        , view = view
        }
