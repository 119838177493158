module Error exposing (main)

import Browser
import CarbonData.Ports as Ports
import Css
import Html.Styled as HS exposing (toUnstyled)
import Html.Styled.Attributes as HSA
import Ui.Button
import Ui.Css.TextStyle
import Ui.Palette as Palette
import Ui.Text
import Ui.TextStyle


type alias Model =
    { error : String
    , errorDescription : String
    }


type Msg
    = LogIn


init : Model -> ( Model, Cmd Msg )
init model =
    ( model, Cmd.none )


main : Program Model Model Msg
main =
    Browser.document
        { init = init
        , subscriptions = subscriptions
        , update = update
        , view = view
        }


view : Model -> Browser.Document Msg
view _ =
    { title = "Error"
    , body =
        [ toUnstyled <|
            HS.div
                [ HSA.css
                    [ Css.displayFlex
                    , Css.flexDirection Css.column
                    , Css.justifyContent Css.center
                    , Css.textAlign Css.center
                    , Css.margin2 (Css.px 85) Css.auto
                    , Css.maxWidth (Css.px 720)
                    ]
                ]
                [ HS.img [ HSA.src "/images/illustrations/sweaty_cow.svg", HSA.css [ Css.width (Css.px 200), Css.margin Css.auto ] ] []
                , Ui.Text.customView [ HSA.css [ Css.margin4 (Css.px 25) Css.auto (Css.px 15) Css.auto ] ]
                    (Ui.TextStyle.heading1
                        |> Ui.TextStyle.withColor Palette.primary600
                    )
                    "Oops!"
                , HS.p [ HSA.css Ui.Css.TextStyle.body ]
                    [ HS.text "It seems that your account doesn’t have access to the footprint calculation tool. To reach the open footprint database, "
                    , HS.a [ HSA.href "https://apps.carboncloud.com/climatehub" ] [ HS.text "visit ClimateHub instead." ]
                    ]
                , HS.p [ HSA.css Ui.Css.TextStyle.body ]
                    [ HS.text "If you’re interested in adding the calculation tool to your account, please "
                    , HS.a [ HSA.href "mailto:sales@carboncloud.com" ] [ HS.text "reach out to our sales team" ]
                    , HS.text " for assistance."
                    ]
                , HS.div [ HSA.css [ Css.margin Css.auto, Css.displayFlex, Css.flexDirection Css.column, Css.property "gap" "20px" ] ]
                    [ Ui.Text.view Ui.TextStyle.body "or"
                    , Ui.Button.view
                        { onClick = Just LogIn
                        , emphasis = Ui.Button.Low
                        , color = Ui.Button.Neutral
                        }
                        (Ui.Button.Text "Log in with a different account")
                    ]
                ]
        ]
    }


subscriptions : Model -> Sub Msg
subscriptions _ =
    Sub.none


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        LogIn ->
            ( model, Ports.logout () )
